/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.loading-outlined svg {
  width: 100%;
  margin: auto;
}
.facility-header__main {
  max-width: 100%;
}

@media screen and (max-width: 1469px) {
  #facilities .facility-header__main .ant-select:first-child,
  #facilities .facility-header__main .ant-select:last-of-type {
    display: none;
  }
}
@media screen and (max-width: 1269px) {
  #facilities .facility-header__main .ant-select:first-child,
  #facilities .facility-header__main .ant-select:last-of-type {
    display: none;
  }
}
@media screen and (max-width: 1069px) {
  #facilities .facility-header__main .ant-select:nth-child(2),
  #facilities .facility-header__main .ant-select:last-child {
    display: none;
  }
}

/* div[class^='ant-'], */
/* div[class*=' ant-'] {
  zoom: 80%;
} */

.hide {
  display: none;
}

.nocursor {
  cursor: not-allowed;
  border: none;
  opacity: 0.2;
  background-color: #e0e0e0 !important;
  z-index: 100;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.nocursor .qtr-icon img {
  /* background-color: rgba(128, 128, 128, 0.2) !important; */
  -webkit-filter: contrast(0.5) saturate(0.5) brightness(0.9) grayscale(1);
  filter: contrast(0.5) saturate(0.5) brightness(0.9) grayscale(1);
}

div.facility-header__main > div.buttons.left > button {
  margin-left: 0px;
}

.facility-report__standard .ant-modal-content {
  width: 1164px;
  height: 420px;
  /* height: 459px; */
  overflow-y: hidden;
  -webkit-transform: translate(-15em, 2rem);
  -ms-transform: translate(-15em, 2rem);
  transform: translate(-15em, 2rem);
  border-radius: 5px;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.facility-report__standard.reporting .ant-modal-content {
  -webkit-transform: translate(-15em, -4rem);
  -ms-transform: translate(-15em, -4rem);
  transform: translate(-15em, -4rem);
}

.onreporting-modal .onreporting-modal__breadcrumb {
  padding-left: 15px;
  margin-top: -25px;
  margin-bottom: 25px;
}

@media screen and (max-width: 1269px) {
  .onreporting-modal .onreporting-modal__breadcrumb {
    padding-left: 15px;
    margin-top: -1px;
    margin-bottom: 15px;
  }
}

.reporting-form__subheading .progress-published .ant-progress {
  transform: translateX(-75px);
}
.reporting-form__subheading .progress-published .ant-progress .ant-progress-outer {
  width: 50%;
}

@media screen and (max-width: 1469px) {
  .facility-report__standard.recording .ant-modal-content {
    width: 55rem !important;
    height: 40rem !important;
    -webkit-transform: translate(-8rem, 2rem) !important;
    -ms-transform: translate(-8rem, 2rem) !important;
    transform: translate(-8rem, 2rem) !important;
    backface-visibility: visible;
  }
  .facility-report__standard .ant-modal-content {
    width: 55rem;
    height: 24rem;
    -webkit-transform: translate(-7rem, -1rem);
    -ms-transform: translate(-7rem, -1rem);
    transform: translate(-7rem, -1rem);
  }
  .reporting-form__subheading .progress-published .ant-progress {
    transform: translateX(-60px);
  }
}

@media screen and (max-width: 1569px) {
  .facility-report__standard.recording .ant-modal-content {
    width: 60rem !important;
    /* height: 44rem !important; */
    -webkit-transform: translate(-8rem, 1rem) !important;
    -ms-transform: translate(-8rem, 1rem) !important;
    transform: translate(-8rem, 1rem) !important;
    backface-visibility: visible;
  }
}

.facility-report__standard .ant-modal-header {
  padding: 0;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
}

.facility-report__standard {
  /* width: 65vw;
  height: 45vh; */
  /* top: 18vh; */
  /* left: 20vw; */
  /* transform: translate(calc(-35% + 0.5px), calc(35% + 0.5px)); */
}

.reporting .ant-modal-content {
  width: 1164px;
  height: 750px; /* 713px */
  border-radius: 5px;
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.facility-report__standard.fac-viewing .ant-modal-title {
  padding: 13px 24px;
}

@media screen and (max-width: 1469px) {
  .reporting .ant-modal-content {
    width: 55rem;
    height: 42rem;
    -webkit-transform: translate(-9rem, -3rem);
    -ms-transform: translate(-9rem, -3rem);
    transform: translate(-9rem, -3rem);
    /* transform: translate(-6rem, -2rem);
    /* transform: translate(-15em, -2rem); */
    backface-visibility: hidden;
  }
}

@media screen and (max-width: 1269px) {
  .reporting .ant-modal-content {
    width: 55rem;
    height: 42rem;
    border-radius: 5px;
    /* -webkit-transform: translate(-10%, -1rem);
    -ms-transform: translate(-10%, -1rem);
    transform: translate(-10%, -1rem); */
    -webkit-transform: translate(-5rem, -5rem);
    -ms-transform: translate(-5rem, -5rem);
    transform: translate(-5rem, -5rem);
  }
  .facility-card__body .lock-text-desc {
    font-size: 12px;
    width: 90%;
  }
}

@media screen and (max-width: 1400px) {
  .reporting .ant-modal-content {
    width: 55rem;
    height: 42rem;
    border-radius: 5px;
    /* -webkit-transform: translate(-10%, -1rem);
    -ms-transform: translate(-10%, -1rem);
    transform: translate(-10%, -1rem); */
    -webkit-transform: translate(-5rem, -5rem);
    -ms-transform: translate(-5rem, -5rem);
    transform: translate(-5rem, -5rem);
  }
  .facility-card__body .lock-text-desc {
    font-size: 10px !important;
    width: 50%;
    overflow: hidden;
    word-wrap: none;
    text-align: left;
  }
}

.recording .ant-modal-content {
  /* width: 979px; */
  height: 750px;
}

.recording .ant-modal-content {
  /* width: 65.5rem;
  height: 40rem;
  -webkit-transform: translate(-15rem, -4rem);
  -ms-transform: translate(-15rem, -4rem);
  transform: translate(-15rem, -4rem); */
}

.recording .ant-modal-body {
  max-height: calc(100% - 126px);
  height: calc(100% - 126px);
  background-color: #ffffff;
}

.recording .onrecording-modal {
  height: 100%;
}
.recording .onrecording-modal > div.ant-row-start {
  -ms-flex-line-pack: justify;
  align-content: space-between;
  margin-bottom: 0.8rem;
  padding-left: 20px;
  margin-right: unset;
}

.recording .facility-reporting__form {
  margin-top: 1.6rem;
}

.recording ul.ant-table-pagination.ant-pagination {
  display: none;
}

.onrecording-modal .facility-reporting__form .ant-form-item {
  width: 100% !important;
  /* margin-bottom: 1rem !important; */
}

.facility-reporting__form .ant-form-item-control input,
.facility-reporting__form .ant-input-affix-wrapper input {
  text-align: right;
}

.recording .ant-modal-body {
  background-color: #ffffff;
}

.reporting .ant-modal-body {
  background-color: #ffffff;
  height: calc(100% - 126px);
  max-height: calc(100% - 126px);
  /* background-color: rgba(10, 10, 120, 0.05); */
}

.thereport__body .arrow-back::before,
.theadhocreport__body .arrow-back::before {
  content: '\2190';
  z-index: 100;
  /* transform: scale(1.2); */
  font-family: var(--font-family-bold);
  /* font-weight: bolder; */
  width: 200px;
  width: 50px;
  -webkit-transform: translateY(-1rem) !important;
  -ms-transform: translateY(-1rem) !important;
  transform: translateY(-1rem) !important;
  position: absolute;
  cursor: pointer;
}

.onreporting-modal .facility-card__body > .ant-card-body {
  height: 100%;
  padding: 12px;
}

.facility-card__body .lock-text-desc {
  font-family: var(--font-family);
  font-size: 14px;
  color: red;
  width: 100%;
  text-align: center;
  margin-left: 1rem;
}

.facility-report__body .card-lockx {
  height: auto;
  vertical-align: middle;
  text-align: center;
  border-radius: 16px;
  background-color: rgba(77, 124, 254, 0.1);
  margin-bottom: 0.5rem;
}

.facility-report__body .card-lockx p {
  padding: 1.6px;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  font-size: 11.2px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #4a90e2;
}

.thereport__body .qtr-lock-icon,
.facility-report__body .qtr-lock-icon {
  display: inline-flex;
  flex-flow: row;
  justify-content: flex-end;
}

.thereport__body .qtr-lock-icon {
  width: 100%;
}

.qtr-icon {
  margin-bottom: 0.4rem;
}

.facility-reporting__body.certview-modal {
  height: 580px;
}

.facility-reporting__body.certview-modal body > img {
  width: 100%;
  height: 100%;
}

.facility-report__standard.fac-cert-viewing.subY22 .ant-modal-content {
  height: 22rem !important;
}

.facility-report__header .report-title .not-active,
.facility-report__header .report-title .active {
  font-size: 14px;
  padding-left: 1rem;
  vertical-align: super;
}

.onreporting-modal .qtr-text {
  height: 26.4px;
  line-height: 1.3;
  overflow: hidden;
}

.reporting .ant-form-item-label > label {
  display: inline;
}

.facility-reporting__form .ant-col {
  /* padding: 0 !important; */
  padding-left: 4px;
  padding-right: 0;
}
.facility-reporting__form.submit-report .ant-col {
  padding: 0 !important;
  padding-right: 0;
}

.facility-reporting__form .ant-form-item-label {
  max-width: 100%;
}

.facility-reporting__form .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  content: '';
}
.facility-reporting__form .ant-form-item-label > label::after {
  content: '';
}

.facility-reporting__form .ant-form-item-control-input-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.facility-reporting__form .ant-form-item-control-input-content button,
.facility-reporting__form button {
  /* border-radius: 4px;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #007ace;
  margin-bottom: -1rem;
  color: #ffffff;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 14px;
  height: 30px; */
}

.facility-reporting__form .ant-form-item-control-input-content button:disabled {
  cursor: not-allowed;
  border: none;
  /* background-color: #e0e0e0; */
  /* opacity: 0.2; */
}

.facility-reporting__form .ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  position: absolute;
  margin-bottom: 16px;
  /* position: relative; */
  -webkit-transform: translate(-0.1rem, 3.8rem);
  -ms-transform: translate(-0.1rem, 3.8rem);
  transform: translate(-0.1rem, 3.8rem);
}

.facility-reporting__form .ant-form-item {
  width: 120%;
}

.facility-reporting__form .form-controlx {
  /* height: 32.8px; */
  height: 41px;
  width: 100%;
  margin-bottom: 0.8rem;
  padding: 0.64rem 0.8rem;
  border-radius: 5px;
  /* border: solid 1px rgba(0, 0, 0, 0.1); */
  /* background-color: rgba(245, 247, 255, 0.2); */
  border: none !important;
}
.facility-reporting__form .form-controlx input {
  /* font-size: 9.6px; */
  font-family: var(--font-family);
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.facility-reporting__form .form-controlx input[aria-valuenow] {
  font-size: 14px;
}

.facility-reporting__form .form-controlx:active,
.facility-reporting__form .form-controlx:focus,
.facility-reporting__form .form-controlx:hover {
  outline: none;
  border-color: #40a9ff;
  border-right-width: 1px;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.facility-reporting__form label {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 1.6px;
  letter-spacing: 1.13px;
  color: #000000;
}

.facility-reporting__form .ant-form-item-control {
  max-width: 100%;
}

.facility-reporting__form .ant-form-item-control input,
.facility-reporting__form .ant-input-affix-wrapper input {
  width: 100% !important;
}

.reporting-form__heading span {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}
.recording-form__heading span.samplePointName {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.reporting-form__subheading {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828ea9;
}

.reporting-form__subheading.recording-form__subheading {
  font-size: 16px;
  color: #9b9b9b;
}

.reporting-form__subheading .ant-row-start {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828ea9;
}

.onrecording-modal .ant-row-start .card-lock-col {
  max-width: unset;
  flex: unset;
}

.onrecording-modal .ant-row-start .card-lock {
  padding: 3px 15px;
  /* width: 67px; */
  /* height: 30px; */
  border-radius: 14.5px;
  background-color: #e7f5ff;
}

.onrecording-modal .ant-row-start .card-lock p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007ace;
}

.reporting-form__subheading.recording-form__subheading span {
  font-size: 16px;
  color: #0cb394 !important;
  /* color: #9b9b9b !important; */
}
.reporting-form__subtext .ant-row-start {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.facility-reporting__form .ant-input-suffix {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.form-controlx.ant-input-affix-wrapper input {
  /* height: 41px; */
  height: auto;
  /* margin-bottom: 1rem;
  padding: 0.8rem 1rem; */
  /* border-radius: 5px; */
  background-color: rgba(245, 247, 255, 0.2);
}

.facility-reporting__form .ant-input-affix-wrapper::before {
  /* display: none; */
  width: 120%;
}

.facility-reporting__body .ant-divider-horizontal {
  margin: 0 0 14px 0;
  /* margin-top: 15px; */
}

.recording-form__heading .ant-divider {
  margin-bottom: 30px;
}

.recording-form__table {
  height: 280px;
  max-height: 280px;
}
.published.recording-form__table {
  height: 400px;
  max-height: 400px;
}

@media screen and (max-width: 1269px) {
  .published.recording-form__table {
    height: 300px; /* 18 rem */
    max-height: 300px;
  }
  .recording-form__table,
  .recording-form__table .ant-table-container {
    height: 180px;
    max-height: 180px;
  }
}
@media screen and (max-width: 1469px) {
  .published.recording-form__table {
    /* height: 18rem; */
    max-height: 18rem;
  }
  .recording-form__table,
  .recording-form__table .ant-table-container {
    height: 180px;
    max-height: 180px;
  }
}

.published.recording-form__heading .report-average > td {
  text-align: center;
  padding-left: 10px;
}

.facility-reporting__form .ant-input-number-input {
  /* height: 32.8px; */
  height: 41px;
  position: absolute;
  top: 0;
  right: 0;
  /* margin-bottom: 1rem;
  padding: 0.8rem 1rem; */
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(245, 247, 255, 0.2);
}

.facility-reporting__form .back-button {
  /* border-radius: 5px;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #fefefe;
  border: 1px solid #007ace !important;
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007ace; */
}

.facility-reporting__form .ant-form-item .ant-input-number-handler-wrap {
  display: none;
}

.onreporting-modal .ant-carousel .slick-slide {
  margin-top: -1rem;
  /* background-color: rgba(10, 10, 120, 0.05); */
}

.onreporting-modal .ant-carousel .slick-dots-bottom {
  bottom: -2rem;
}

.onreporting-modal .ant-carousel .slick-dots li {
  height: 0.4rem;
  width: 2.4em;
  border: 1px solid lightskyblue;
}
.onreporting-modal .ant-carousel .slick-dots li button {
  height: 100% !important;
  border: 1px solid darkslategrey !important;
  background-color: darkslategrey !important;
}
.facility-report__standard.adhoc .slick-dots li {
  /* height: 0.4rem;
  width: 2.4em; */
  border: 1px solid lightskyblue;
}
.facility-report__standard.adhoc .slick-dots li button {
  /* height: 100% !important; */
  border: 1px solid darkslategrey !important;
  background-color: darkslategrey !important;
}

.adhoc-thereport__body input.form-controlx {
  background-color: #ffffff;
}
form.facility-reporting__form button.sample-record-add-btn {
  -webkit-transform: translateY(-10%);
  -ms-transform: translateY(-10%);
  transform: translateY(-10%);
  height: 39px;
  /* -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px); */
}
form.facility-reporting__form button.sample-record-add-btn {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  vertical-align: middle;
  /* height: 30px; */
}

.submitBtnReport {
  /* margin-right: 0.8rem; */
  width: 100%;
  padding-right: 15px;
  /* -webkit-transform: translateY(3rem);
  -ms-transform: translateY(3rem);
  transform: translateY(3rem); */
}

@media screen and (max-width: 1269px) {
  .submitBtnReport {
    padding-right: 0px;
  }
}

.facility-reporting__form.submit-report .ant-col-3 {
  margin-right: 12px;
  flex: unset;
}
.facility-reporting__form.submit-report .ant-col-2 {
  /* margin-right: 12px; */
  flex: unset;
  max-width: unset;
}

.submitBtnRecord {
  margin-right: 0.8rem;
  width: 100%;
}

.onrecording-modal .ant-row-start > .ant-col.ant-col-offset-1.ant-col-24 {
  height: 27rem;
}
/* .submitBtnRecord {
  position: absolute;
  bottom: 0;
} */

.submitBtnReporting {
  -webkit-transform: translateY(11.4rem);
  -ms-transform: translateY(11.4rem);
  transform: translateY(11.4rem);
}

.submitBtnReporting .ant-row-end .ant-col-2 {
  max-width: unset;
}

.submitBtnRecord {
  margin-right: 0.8rem;
  width: 100%;
  /* -webkit-transform: translateY(2rem);
  -ms-transform: translateY(2rem);
  transform: translateY(2rem); */
}

.submitBtnRecording {
  width: 100%;
  height: 30px;
  /* bottom: 0; */
}

.facility-reporting__form .ant-col {
  /* height: 41px; */
}

.qtr-published {
  height: 16px;
  text-align: center;
}

.qtr-published .ant-progress-show-info .ant-progress-outer {
  display: none;
}

.qtr-published .ant-progress-status-success .ant-progress-text {
  /* color: #52c41a */
  color: #2e698b;
}

.qtr-published .ant-progress-text {
  width: 100%;
  margin: 0;
  text-align: center;
}

.qtr-published .ant-progress-text::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 720;
  content: '✓';
  /* color: #52c41a; */
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.thereport__body .arrow-back::before {
  content: '\2190';
  z-index: 100;
  width: 160px;
  -webkit-transform: translateY(-1.6rem);
  -ms-transform: translateY(-1.6rem);
  transform: translateY(-1.6rem);
  position: absolute;
  cursor: pointer;
}
.facility-report__body .arrow-back::before {
  content: '\2190';
  z-index: 100;
  width: 160px;
  font-family: var(--font-family-bold);
  -webkit-transform: translateY(-1.6rem);
  -ms-transform: translateY(-1.6rem);
  transform: translateY(-1.6rem);
  position: absolute;
  cursor: pointer;
}
.thereport__body .arrow-back::before {
  content: '\2190';
  z-index: 100;
  width: 160px;
  -webkit-transform: translateY(-1.6rem) !important;
  -ms-transform: translateY(-1.6rem) !important;
  transform: translateY(-1.6rem) !important;
  position: absolute;
  cursor: pointer;
}

.adhoc-thereport__body {
  margin-left: 16px;
  font-family: var(--font-family);
}

.adhoc-thereport__body .ant-col {
  max-width: 100%;
}

.adhoc-thereport__body label {
  font-size: 16px;
}

.adhoc-thereport__body .ant-picker-range,
.adhoc-thereport__body .ant-picker {
  width: 100%;
  height: 41px;
  border-radius: 5px;
}

.adhoc-thereport__body .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  content: '';
  display: none;
}

.adhoc-thereport__body .ant-form-item-control-input {
  padding-bottom: 12px;
}
.adhoc-thereport__body .ant-form-item-control-input-content input,
.adhoc-thereport__body .ant-form-item-control-input-content textarea {
  border-radius: 5px;
}

.facility-report__standard.adhoc .add-new-adhoc-btn {
  justify-content: flex-end !important;
}
.facility-report__standard.adhoc .add-new-adhoc-btn button {
  /* border-radius: 35% 35% 35% 35%; */
  /* border-radius: 50% 20% 20% 50%; */
  /* transform: scale(1.25) translate(-10%, -30%) rotate(90deg); */
}

.facility-report__standard.adhoc .add-new-adhoc-btn svg {
  margin-right: 0px !important;
}
.facility-report__standard.adhoc.add-x2 .ant-modal-content {
  height: 32rem;
}
.facility-report__standard.adhoc.add-x5 .ant-modal-content {
  /* height: 42rem; */
  height: 46rem;
}
.facility-report__standard.adhoc.add-x5
  .ant-modal-content
  .onreporting-modal
  .onreporting-modal__breadcrumb {
  margin-top: -10px;
  margin-bottom: 10px;
}

.facility-report__standard.adhoc .ant-carousel {
  padding-left: 12px;
}
.facility-report__standard.adhoc .ant-carousel .slick-dots-bottom {
  bottom: -1rem;
}

.facility-report__body.onreporting-modal .onreporting-modal__breadcrumb {
  margin-top: -10px;
  margin-bottom: 10px;
}

/*====================================*/
/* FACILITY MODAL STYLES */
/*====================================*/
.standard-report-modal .ant-modal-content {
  width: 1000px;
  margin-left: -15vw;
  height: auto;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

#facilities .small-heading {
  font-family: var(--font-family-bold);
  /* font-size: 18px; */
  /* font-weight: 900; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.steps-content {
  /* margin-top: 16px; */
  /* border: 1px dashed #e9e9e9; */
  border-radius: 2px;
  /* background-color: #fafafa; */
  min-height: 160px;
  text-align: left;
  padding-top: 1.6rem;
}

.step-form {
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
  overflow-y: unset;
}

.ant-form-item-explain {
  margin-top: -0.72rem;
}

.steps-action {
  margin-top: 19.2px;
  margin-bottom: 0.8rem;
}

h4.px-0 {
  padding-left: 0 !important;
}

.step-form .ant-form-item {
  margin-bottom: 1px;
}

.ant-select-selection,
.steps-content .form-control {
  margin-top: 0.64rem;
  margin-bottom: 1.44rem;
}

.steps-content .form-control {
  margin-top: 0.64rem;
  margin-bottom: 1.44rem;
  color: rgba(0, 0, 0, 0.65);
  font-family: var(--font-family);
}

.form-controlx {
  height: 41px;
  width: 100%;
  margin-bottom: 0.8rem;
  padding: 0.64rem 0.8rem;
  font-family: var(--font-family);
  /* border-radius: 5px;
  border: solid 1px rgba(97, 90, 90, 0.1); */
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(245, 247, 255, 0.2);
  border-radius: 5px;
  border: solid 1px rgba(216, 216, 216, 1);
}

.form-controlx:active,
.form-controlx:focus,
.form-controlx:hover {
  outline: none;
  border-color: #40a9ff;
  border-right-width: 1px;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.step-form input::-webkit-input-placeholder {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
  opacity: 0.4;
}
.step-form input::-moz-placeholder {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
  opacity: 0.4;
}
.step-form input:-ms-input-placeholder {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
  opacity: 0.4;
}
.step-form input::placeholder {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
  /* color: rgba(216, 216, 216, 0.4) */
  /* color:lightgray; */
}

.form-controlxx {
  /* height: 41px;
  width: 100%; */
  /* margin-bottom: 1rem;
  padding: 0.8rem 1rem; */
  font-family: var(--font-family);
  color: rgba(0, 0, 0, 0.65);
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(245, 247, 255, 0.2);
}

.form-controlxx:active,
.form-controlxx:focus,
.form-controlxx:hover {
  /* outline: none; */
  border-color: #40a9ff;
  border-right-width: 1px;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

/* .form-controlxx .ant-select-selector span {
  transform: translateY(40px);
  display: inline-flex;
  /* position: relative; */
/* display: block; */
/* max-width: 600px; */
/* border: 1px solid #ddd; */
/* margin-top: 1.5em; */
/* margin-bottom: 1em; */
/* }  */

.step-form .ant-form-item-control {
  max-width: 100%;
}

.step-form .ant-form-item-label {
  max-width: 50%;
}

.step-form .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  content: '';
}
.step-form .ant-form-item-label > label::after {
  content: '';
}

.tagClass {
  --antd-wave-shadow-color: #1890ff;
  text-align: left;
  line-height: 1.5715;
  list-style: none;
  cursor: move;
  padding-top: 2.4px;
  padding-bottom: 2.4px;
  padding-left: 9.6px;
  padding-right: 9.6px;
  border-radius: 4.8px 4.8px 4.8px 4.8px;
  margin-bottom: 0.8rem;
  margin-right: 0.8em;
  /* width: 110px;
  height: 25px; */
  border-radius: 11.6px;
  background-color: #e7f5ff;
  /* font-size: 12px; */
  /* font-weight: normal; */
  font-stretch: normal;
  /* font-style: normal;
  line-height: normal; */
  /* letter-spacing: normal; */
  color: #0c5bab;
}

.selectedClass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  margin-top: 8px;
  margin-bottom: 0.8rem;
}

.removeClass {
  /* background-color: rgba(0, 0, 0, 0.1);
  padding-right: 2px;
  padding-bottom: 1px;
  border-radius: 80% 85% 75% 90%; */
  color: rgba(0, 0, 0, 0.2) !important;
  padding-left: 2px;
  margin-left: 4px;
  /* color: white; */
  /* border-radius: 50% 50%; */
}

.removeClass:hover {
  color: #d0021b !important;
}

.removeClass:hover {
  color: rgba(221, 42, 96, 0.8);
}

.suggestionsClass .activeSuggestionsClass {
  list-style: none;
}

.tagsClass .suggestionsClass {
  height: 10rem;
  overflow: scroll;
}

.form-controlxx.ant-select:hover {
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
  /* outline: none; */
}

.form-controlxx.ant-select {
  margin-bottom: 0.8rem;
  /* padding: 0.8rem 1rem; */
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  outline: none;
  border-radius: 5px !important;
  -webkit-appearance: none;
}

.form-controlxx .ant-select-selector {
  height: 41px !important;
  border-radius: 5px !important;
}

.form-controlxx .ant-select-selection-placeholder,
.form-controlxx .ant-select-selection-item {
  line-height: 41px !important;
}

.ant-form-item-control-input .ant-form-item-children-icon {
  top: 40% !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.icon-setting {
  width: 27.2px;
  height: 27.2px;
  border-radius: 2.4px;
  background-color: rgba(16, 112, 202, 0.08);
  -o-object-fit: contain;
  object-fit: contain;
  margin-left: -1.2rem;
  margin-bottom: 3.2px;
}

.icon-setting:hover,
.icon-setting:active {
  border: 1px solid rgba(16, 112, 202, 0.8);
}

.icon-setting svg {
  width: 16px;
  height: 26.4px;
  padding: 1px;
  color: rgba(16, 112, 202, 0.8);
}

.icon-logo {
  width: 27.2px;
  height: 27.2px;
  /* border-radius: 3px;
  background-color: rgba(16, 112, 202, 0.08); */
  /* object-fit: contain; */
  /* margin-left: -1.5rem; */
}

.icon-logo img {
  width: 16px;
  margin-left: 0.4rem;
  vertical-align: super;
  /* height: 33px; */
  padding: 1px;
  color: rgba(16, 112, 202, 0.8);
}

.icon-choose .ant-form-item-explain {
  margin-left: -1.2rem;
  margin-bottom: 4px;
  /* margin-top: 0.2px; */
  padding-top: 5.6px;
}

.icon-delete-row,
.icon-edit-row {
  width: 27.2px;
  height: 27.2px;
  border-radius: 2.4px;
  background-color: rgba(16, 112, 202, 0.08);
  -o-object-fit: contain;
  object-fit: contain;
  /* margin-left: -1.5rem;
  margin-bottom: 4px; */
}

.icon-delete-row:hover,
.icon-delete-row:active {
  /* border: 1px solid rgba(16, 112, 202, 0.8); */
  color: rgba(221, 42, 96, 0.8);
}
.icon-edit-row:hover:not(.not-editing),
.icon-edit-row:active:not(.not-editing) {
  color: rgba(42, 114, 221, 0.8);
}

.icon-delete-row svg,
.icon-edit-row svg {
  width: 16px;
  height: 26.4px;
  padding: 1px;
}

.icon-edit-row.not-editing {
  cursor: not-allowed;
  border: none;
  color: #e0e0e0;
  opacity: 0.2;
}

.tagged-input .ant-form-item-explain > div {
  margin-top: -0.48rem;
}

.tag-list {
  list-style: none;
}

.step-form-table {
  margin-bottom: 0.8rem;
  margin-top: 10px;
}

.step-form .ant-btn {
  height: 30px;
}

.step-form-table ul.ant-pagination {
  display: none;
}
.step-form-table td.ant-table-cell {
  font-family: var(--font-family);
  font-size: 14px; /* 16px */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828ea9;
}

.step-form-table.spt-data tr.ant-table-row td.ant-table-cell:nth-child(4) {
  text-align: center;
}

.step-3-header {
  /* width: 82px;
  height: 20px; */
  display: block;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: normal;
  color: #0c5bab;
  text-transform: capitalize;
  margin-top: 0.8rem;
  margin-bottom: 8.8px;
}

.accredited,
.not-accredited {
  text-transform: capitalize;
  width: 64px;
  height: 16px;
  color: #00ab44;
}

.not-accredited {
  color: #eb0a4ddc;
}

.ant-input-group {
  /* height: 2.7em; */
  /* width: 18em; */
  width: 109%;
  -webkit-transform: translate(-0.969em, -0.72em);
  -ms-transform: translate(-0.969em, -0.72em);
  transform: translate(-0.969em, -0.72em);
  backface-visibility: visible;
  /* transform: translate(-1.19em, -0.9em); */
}

.form-controlx span.ant-input-group {
  width: 100%;
}
.form-controlx .ant-input-group-wrapper input {
  /* height: 2.16em;
  width: 14.64em; */
}

.form-controlx .ant-input-group > .ant-input:last-child {
  /* height: 30px; */
  height: 41px;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  width: 113.6%;
  box-shadow: none;
  /* border: none !important;
  outline: none; */
}
.form-controlx .ant-input-group:active,
.form-controlx .ant-input-group:focus {
  border-radius: 5px;
  border-color: #40a9ff !important;
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); */
}

.form-controlx.input-addon {
  border: none !important;
}

.form-controlx .ant-input-group-addon:first-child .ant-select-selector,
.form-controlx .ant-input-group-addon:first-child .ant-select {
  outline: none;
}

.form-controlx span.ant-input-group-addon:first-child {
}

.step-form .ant-input-group-addon .ant-select-selector {
  height: 41px !important;
  display: flex;
  align-items: center;
}
/* .step-form .ant-input-group-addon .ant-select-selector:hover,
.step-form .ant-input-group-addon .ant-select-selector:active {
  border: 1px solid #40a9ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
} */

.form-controlx span.ant-input-group-addon {
  height: 30px;
  vertical-align: middle;
  border-radius: 5px 0 0 5px;
  /* border: none;
  outline: none; */
}

.form-controlx span.ant-input-group-addon:first-child {
  height: 39px;
  vertical-align: middle;
  transform: translate(1.5px);
  /* border-radius: 5px 0 0 5px; */
  /* border: none;
  outline: none; */
}

.facility-create-result .ant-modal-content {
  /* position: sticky; */
  position: fixed;
  /* top: 25vh; */
  /* left: 15vw; */
  /* right: 15vw; */
  width: 35rem !important;
  height: 33rem;
  /* width: 40vw;
  height: 70vh; */
  border-radius: 5px;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.steps-content .step-form .ant-form-item-control .cert-upload-form {
  display: block;
  margin-bottom: 0.8rem;
}

.ant-modal-close {
  display: none;
}

.ant-modal-body {
  height: 100%;
  width: 100%;
}

.facility-form-result {
  height: 100%;
  width: 100%;
}

.ant-result-icon {
  margin-top: 14%;
}

.facility-form-result .ant-result-title {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #828ea9;
}

.facility-form-result .ant-result-title {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.ant-result-icon svg {
  width: 104.8px;
  height: 104.8px;
  -o-object-fit: contain;
  object-fit: contain;
}

.ant-modal-boody {
  width: 499.2px;
  height: 80vh;
  border-radius: 5px;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.ReactTags__tagInput {
  /* border: solid 1px rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  outline: none;
  opacity: 1;
}

.ReactTags__tagInput:hover {
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  opacity: 1;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
  outline: none;
  border-right-width: 1px !important;
}

.ReactTags__tagInput input {
  /* font-size: 11.2px;
  opacity: 1; */
  /* font-family: inherit; */
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: rgba(245, 247, 255, 0.2);
  color: rgba(0, 0, 0, 0.65);
  border: solid 1px rgba(216, 216, 216, 1);
  outline: none;
}

.ReactTags__tagInput input:active {
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
  outline: none;
  /* border-right-width: 1px !important; */
}

.form-controlx.tag-field-error {
  border-color: #ff4d4f;
}

.ReactTags__tagInput input:hover {
  border: none;
  outline: none;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
  outline: none;
  /* border-right-width: 1px !important; */
}

.step-form .form-controlx .ant-input-number-input {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 12px;
  width: 20em;
  height: 41px;
  border: none;
  outline: none;
  /* border-radius: 5px; */
  /* border: solid 1px rgba(0, 0, 0, 0.1); */
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(245, 247, 255, 0.2);
}

/* .ant-form-item .ant-input-number-handler-wrap {
  display: none;
} */

.step-form .add-sample-point-btn .ant-form-item-control {
  place-items: flex-end;
}
/* 
 .icon-choose-modal .ant-modal-content {
  
  background-color: #fffffe;
  -webkit-transform: translate(-4.08rem, 7.28rem);
  -ms-transform: translate(-4.08rem, 7.28rem);
  transform: translate(-4.08rem, 7.28rem);
 
} */

.icon-choose-modal .ant-modal-content .ant-modal-close {
  display: block;
}

.icon-choose-modal .ant-modal-body {
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
}

.icon-choose-modal .ant-modal-body .ant-row {
  width: 100%;
  overflow: scroll;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.icon-choose-modal .ant-modal-body .ant-row .ant-col-2 {
  max-width: unset;
 
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
 
}


.icon-choose-modal .ant-modal-body .ant-row .ant-radio-button-wrapper {
  height: 4rem;
  border: none;
}

.icon-choose-modal .ant-modal-body .ant-radio-group {
 
  height: 100%;
}


.icon-choose-modal .ant-modal-body .ant-row {
  -ms-flex-pack: distribute;
  justify-content: space-around;
  height: 100%;
  padding-top: 8px;
}

.icon-choose-modal .ant-modal-body .ant-row .ant-radio-button-wrapper .icon-logo img {
  width: 3.2rem;
  height: 3.2rem;
}

.icon-choose-modal .ant-modal-body .ant-row .ant-radio-button-wrapper .icon-logo img:hover {
  width: 3.2rem;
  height: 3.2rem;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  border: 1px solid rgba(24, 144, 255, 0.2);
  
} /**/

.step-form .suggestionsClass ul,
.step-form .suggestionsClass .ant-list,
.step-form .suggestionsClass .ant-list-item {
  list-style: none !important;
  cursor: pointer;
  padding-top: 5px;
}

.step-form .suggestionsClass ul li {
  padding-top: 5px;
  letter-spacing: 2px;
}

#facilities .ant-steps-item-icon > .ant-steps-icon {
  width: 25.6px;
  height: 25.6px;
  top: 1px;
  font-size: 14px;
}
#facilities .ant-steps-item-icon {
  line-height: 25.6px;
}

ul.ant-table-pagination li.ant-pagination-item,
ul.ant-table-pagination li.ant-pagination-prev,
ul.ant-table-pagination li.ant-pagination-next,
ul.ant-table-pagination li.ant-pagination-prev,
ul.ant-table-pagination li.ant-pagination-next,
ul.ant-table-pagination li.ant-pagination-item {
  height: 25.6px;
  width: 25.6px;
  line-height: 24px;
}

ul.ant-table-pagination .ant-pagination,
ul.ant-table-pagination .ant-pagination {
  font-size: 12px;
}
